.signature body {
  background-color: gray;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.signature .container {
  width: 500px;
  height: 300px;
  top: 10%;
  left: 10%;
}

.signature .sigContainer {
  width: 80%;
  height: 300px;
  margin: 0 auto;
  background-color: #fff;
}

.signature .sigPad {
  width: 100%;
  height: 300px;
}

.signature .buttons {
  width: 100%;
  height: 300px;
}

.signature .sigImage {
  background-size: 200px 50px;
  width: 200px;
  height: 300px;
  background-color: white;
}
.signature canvas {
  border: 1px solid #dfdfdf;
  width: 66%;
  height: 150px;
  border-radius: 4px;
  box-shadow: 0 0 5px rgb(0 0 0 / 2%) inset;
}
