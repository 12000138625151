@import "https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700";

html,
body,
#root,
.App {
  width: 100%;
  height: 100%;
}
body h2 {
  font-size: 21px;
  font-weight: bold;
}

.btn-primary {
  color: #fff;
  background-color: #424242 !important;
  border-color: #424242 !important;
}

body {
  font-family: "Poppins", sans-serif;
  background: #fafafa;
}

a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;
}
.header {
  background: #fff;
  -webkit-box-shadow: 0px 3px 0px #eaeaea;
  -moz-box-shadow: 0px 3px 0px #eaeaea;
  box-shadow: 0px 3px 0px #eaeaea;
}
.main-container {
  flex: 1;
  background: #f4f4f4;
  padding: 0 !important;
}
.btn-primary {
  color: #fff;
  background-color: #f97423 !important;
  border-color: #f97423 !important;
}
.btn-primary:hover {
  color: #fff;
  background-color: #d9621a !important;
  border-color: #d9621a !important;
}
.btn-secondary {
  color: #fff;
  background-color: #424242 !important;
  border-color: #424242 !important;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #252525 !important;
  border-color: #252525 !important;
}

.sidebar-container {
  flex: 0 0 250px;
  transition: flex 0.5s;
}
.closed-sidebar .sidebar-container {
  flex: 0;
}
.bg-primary {
  --bs-bg-opacity: 1;
  background-color: #1995d8;
}
li.bottom-stuck.nav-item {
  position: absolute;
  bottom: 10px;
}

.main-content {
  flex-grow: 1;
  height: auto;
  margin: 10px;
}

.navbar-dark .navbar-toggler {
  background: #333 !important;
}

.menu-button {
  font-size: 32px;
  margin: 10px;
}
.menu-icon {
  margin-right: 10px;
  font-size: 24px;
}
.start-calling {
  margin-left: auto;
}
a.nav-link {
  color: #333;
}
.sidebar .logo {
  width: 150px;
  height: auto;
  margin: 0 auto;
}
.content-container {
  padding: 30px 20px;
}

.ml-auto {
  margin-left: auto;
}
select#period {
  max-width: 210px;
}
.info-block {
  background: #fff;
  padding: 10px;
  display: flex;
  align-items: center;
  height: 110px;
  margin-bottom: 20px;
  justify-content: center;
  box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
  border-radius: 3px;
}
.info-block .icon {
  width: 20%;
  text-align: right;
}
.info-block .icon svg {
  color: #1996d8;
  font-size: 24px;
}
.info-block .data {
  margin-left: 20px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.info-block .data h3,
.info-block .data p {
  flex: 0 0 100%;
  margin: 0;
  font-weight: bold;
}

.info-block .data p {
  font-size: 12px;
  font-weight: bold;
}
label.form-label {
  font-size: 18px;
  font-weight: bold;
}

.text-right {
  text-align: right !important;
}

.table-block {
  background: #fff;
}

.table-header {
  padding: 10px;
  font-weight: bold; /* border-bottom: 1px solid #dee2e6; */
}

.table-block .inner-row.row .col {
  margin: 0 12px;
  border-bottom: 1px solid #dee2e6;
}
.table-block .inner-row.row .col:first-child {
  margin-right: 0;
}

.table-block .inner-row.row .col:last-child {
  margin-left: 0;
}
.table > tbody {
  border-top: 2px solid #1996d8 !important;
}

.table-block table {
  margin-bottom: 0;
}
.table > :not(caption) > * > * {
  padding: 0.5rem 20px !important;
}
.table-block thead th {
  color: #747474;
  font-weight: 500;
  font-size: 14px;
}
.header .logo {
  width: 90px;
  height: auto;
  /* margin: 0 auto; */
  transition: all 0.5s;
  margin-top: -90px;
}

.closed-sidebar .header .logo {
  margin-top: 0;
}
.table-hover tbody tr {
  cursor: pointer;
}
.start-calling.btn {
  background: #51ad22;
  color: #fff;
  margin-left: auto;
  font-weight: bold;
}
.start-calling:hover {
  color: #fff !important;
  background: #408e17;
}
.menuicon {
  display: flex;
  align-items: center;
  padding-right: 10px;
}
.profile-image img {
  max-width: 100px;
}

.process-type {
  font-weight: bold;
  background: #fff;
  padding: 30px;
  justify-content: center;
  text-align: center;
  cursor: pointer;
}

.process-type:hover,
.process-type.active {
  background: #1891d3;
  color: #fff;
}

.process-title {
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0 20px;
}
button.call-button {
  border-radius: 50%;
  font-size: 22px;
  padding: 20px;
}

button.call-button {
  border-radius: 50%;
  font-size: 22px;
  padding: 20px;
  background-color: #21b606;
  border-color: #21b606;
}

button.call-button svg {
  width: 40px;
  height: 40px;
}

button.call-button:hover {
  background-color: #198f04;
  border-color: #198f04;
}
.calling-active button.call-button {
  background-color: #ef1818;
  border-color: #ef1818;
}
.call-block .card {
  margin-bottom: 20px;
}
.profile-details h3 {
  font-size: 16px;
  font-weight: bold;
}
.card-list {
  display: flex;
  flex-wrap: wrap;
}
.card-list .list-item {
  flex: 0 0 50%;
}
.card-list .list-item li.title {
  list-style: none;
  padding: 0;
  margin: 0;
}
.card-list .list-item li {
  margin-left: 15px;
}
.profile-details.ext h3 {
  font-size: 24px;
}
.tag-cloud {
  display: flex;
}
.tag-cloud .tag {
  background: #1b97d7;
  color: #fff;
  padding: 3px 8px;
  margin: 0 2px;
  border-radius: 8px;
  display: flex;
  font-size: 10px;
  font-weight: bold;
}
.add-new .info-block {
  background: transparent;
  box-shadow: none;
  cursor: pointer;
}
.address {
  background: #c0e9ff;
  padding: 10px;
  margin-bottom: 10px;
}
.button-block button:first-child {
  margin-right: 15px;
}
label.form-label {
  font-size: 14px;
}
.modal .col-first,
.modal .col-middle {
  border-right: 2px dotted #d0d4d8;
}
/* ---------------------------------------------------
    Auth  STYLE
----------------------------------------------------- */
.auth-body {
  text-align: center;
  position: absolute;
  width: 100%;
  max-width: 400px;
  left: 0;
  right: 0;
  margin: auto;
  top: 20%;
}
.auth-body .auth-logo {
  margin-bottom: 40px;
}
.auth-body .auth-logo img {
  width: 200px;
}
.auth-body .form-floating > .form-control,
.auth-body .form-floating > label {
  padding: 10px !important;
  height: calc(50px + 2px);
}
.auth-body .card {
  padding: 20px 30px;
}
.auth-body .card .card-body h3 {
  font-weight: bold;
  margin-bottom: 20px;
  font-size: 21px;
}
.process-type {
  margin-bottom: 10px;
  font-size: 12px;
  position: relative;
}
.process-type .selected {
  position: absolute;
  top: 0;
  right: 0;
}
.profile-block.card {
  transition: all 0.5s;
}
.profile-block.calling-active.card {
  background: #323232;
  color: #fff;
}

/* .profile-block .row {
  align-items: center;
} */
.navigate-buttons > div {
  display: inline-block;
  padding: 6px;
  background: #cbcbcb;
  margin-right: 10px;
  cursor: pointer;
}
.navigate-buttons {
  padding: 10px 0;
}
.navigate-buttons svg {
  color: #777;
  font-size: 21px;
}
.remarks {
  background: #f1f1f1;
  padding: 10px;
}
.remarks .remark {
  display: flex;
  border-bottom: 1px solid #221e1f;
  padding: 10px 0;
}
.remarks .remark .remark-date {
  font-size: 11px;
  font-weight: bold;
  margin-right: 10px;
  background: #e4e4e4;
  padding: 5px 10px;
}
.remarks .remark:first-child {
  padding-top: 0;
}
.remarks .remark:last-child {
  border-bottom: 0;
  padding-bottom: 0;
}

.profile-add {
  width: 90px;
  display: block;
  margin: 0 auto;
  height: 90px;
  border: 1px solid;
  border-radius: 50%;
  object-fit: cover;
}
.profile-add.no-center {
  margin: 0;
  border-radius: 0;
  object-fit: contain;
}
.form {
  width: 100%;
  max-width: 800px;
  margin: 40px auto 0;
  position: relative;
}

.form h1 {
  font-size: 26px;
  font-weight: bold;
}

.form h3 {
  font-size: 18px;
  font-weight: bold;
}

.file-upload-button {
  display: inline-block;
  font-weight: bold;
  padding: 0;
  margin: 0;
}
/* select#diploma {
  width: 60%;
  display: inline-block;
} */
.work-experience .add-button {
  display: flex;
  height: 100%;
  align-items: center;
}

.add-button {
  display: inline-block;
  padding: 0 10px;
  cursor: pointer;
  color: green;
}

.add-button {
  display: inline-block;
  padding: 0 10px;
  cursor: pointer;
  color: green;
}

.list {
  background: aliceblue;
  display: flex;
  flex-wrap: wrap;
  font-size: 10px;
  font-weight: bold;
  padding: 10px;
  margin-top: 10px;
}

.list .list-item {
  flex: 1 0 100%;
  padding: 5px 10px;
}
.error {
  color: #df3939;
  background: #ffb6b6;
  margin: 10px 0 0 0;
  padding: 3px 10px;
  font-size: 13px;
  font-weight: bold;
}
.work-experience .add-button {
  display: flex;
  height: 100%;
  align-items: center;
}

.educucation-block.work-experience,
.educucation-block.certificate {
  margin-top: 30px;
}

.breadcrumbs .breadcrumb-single a {
  margin-right: 0px;
  text-decoration: none;
  font-weight: bold;
  color: #1c99dc;
}
.action-buttons {
  flex: 1;
  display: flex;
  justify-content: end;
  gap: 10px;
}

.flex {
  display: flex;
}

.breadcrumbs .breadcrumb-single {
  align-items: center;
  flex: 1;
  color: #999;
  font-weight: 500;
}

.breadcrumbs .breadcrumb-single svg {
  margin: 0 8px 0 10px;
  padding: 0;
  font-size: 13px;
}

.info-items .info-item {
  background: #fff;
  padding: 0px;
  position: relative;
  border-radius: 15px;
}
.info-item .status-update {
  position: absolute;
  bottom: 0;
  width: 100%;
  align-items: center;
  margin-left: 0;
  margin-right: 0;
  background: #e9f7ff;
  border-radius: 0 0 8px 8px;
}
.info-item .status-update input {
  border: 0;
  background: transparent;
}
.info-item .status-update input:focus {
  outline: none;
  box-shadow: none;
}
.submit-update {
  font-size: 12px;
  font-weight: bold;
  background: none;
  border: 0;
}
.remarks-block {
  height: 447px;
  padding: 10px 10px 40px;
  overflow-y: auto;
  overflow-x: hidden;
}
.submit-update:hover {
  opacity: 0.6;
}
.info-items.row {
  gap: 30px;
  margin-top: 30px;
}
.action-buttons .button {
  background: #fff;
  padding: 10px;
  border-radius: 15px;
  cursor: pointer;
  color: #888;
}
.breadcrumbs {
  margin-bottom: 20px;
}
.breadcrumbs .flex {
  padding-right: 0;
  align-items: center;
}
.action-buttons .print {
  padding: 10px;
  width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
}

.action-buttons .button.edit {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.action-buttons .button.edit svg {
  font-size: 18px;
}
.hide {
  display: none !important;
}
.profile-item.col-5 {
  border-right: 1px solid #ebebeb;
}
.list-block {
  padding: 25px;
}
.profile-item .list-block {
  text-align: center;
}
.list-block .profile-image {
  margin-bottom: 13px;
}
.list-block .profile-image img {
  border-radius: 50%;
  max-width: 100px;
  border: 1px solid #ebebeb;
}
.list-block .full-name {
  font-weight: bold;
  font-size: 21px;
}
.list-block .list-item .item-title {
  display: block;
  color: #999;
  margin-bottom: 10px;
}

.list-block .list-item {
  color: #313131;
  border-bottom: 1px solid;
  margin-right: 50px;
  font-weight: 500;
  padding-bottom: 10px;
  margin-bottom: 30px;
}
span.item-details {
  font-size: 12px;
}

.profile-item .list-block .list-item {
  margin: 0;
  border: 0;
}

.profile-item .list-block.row {
  padding-top: 0px;
}

.profile-item .list-block {
  padding-bottom: 20px;
}

.list-block .email {
  font-weight: 500;
  color: #999;
}

.profile-item .list-block .list-item .item-title {
  margin-bottom: 0;
}
.dashboard-item {
  background: #fff;
}

.dashboard-item ul {
  list-style: none;
  padding: 0;
}

.dashboard-item ul li {
  border-top: 1px solid #ececec;
  padding: 5px 0 5px 10px;
  cursor: pointer;
}

.dashboard-item h2 {
  padding: 10px;
  margin: 0;
}
.align-center {
  align-items: center !important;
  display: flex;
}
.align-center .add-button {
  border: 1px solid;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.diploma-list {
  overflow-y: scroll;
  height: 100px;
}
.remark-item {
  display: flex;
}

.remark-item .date {
  font-size: 10px;
  font-weight: 700;
}
.remarks-block .remark-item {
  border-bottom: 1px solid #ebebeb;
  padding: 10px 0;
}

.remark-item .description {
  font-size: 13px;
}
.experience {
  padding: 10px 10px 0;
  align-items: center;
}

.experience .info {
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 20px;
}

.experience .title {
  font-weight: bold;
}

.experience .function {
  font-weight: 400;
  font-size: 12px;
}

.experience .period {
  text-align: right;
  font-weight: bold;
  font-size: 12px;
}

.experience .function,
.experience .period {
  margin-bottom: 7px;
}
.experience-item .experience {
  background: #fff;
  margin: 0;
  border-radius: 13px;
  margin-bottom: 10px;
}

.breadcrumbs {
  margin-bottom: 20px;
}

.experience-items {
  margin-top: 20px;
}
.experience-items h2 {
  margin-bottom: 20px;
}
.sidebar-container.col.col-2 {
  position: sticky;
  top: 1px;
}

select#statusChange {
  position: absolute;
  width: 150px;
  right: -100%;
  top: 4px;
}

.action-buttons .button.status {
  position: relative;
}
.educucation-block.languages {
  margin-bottom: 30px;
}
a.nav-link.active {
  font-weight: bold;
  color: #1c99db;
}
/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
.sidebar {
  /* min-width: 300px;
  max-width: 300px; */
  background: #fff;
  color: #333;
  margin-left: -300px;
  transition: all 0.5s;
}

.sidebar.is-open {
  margin-left: 0;
  transition: 0.5s;
  position: sticky;
  top: 0;
}
button img {
  max-height: 25px;
}
.sidebar-header {
  /* background: #1995d8; */
  text-align: center;
  padding: 20px 0 40px;
}

.sidebar-header h3 {
  color: #333;
  padding: 14px 15px 10px;
  font-size: 20px;
  margin-bottom: 40px;
  text-align: center;
}

.sidebar ul p {
  color: #fff;
  padding: 10px;
}

.menu-open {
  background: #6d7fcc;
}

.nav-item:hover {
  color: #7386d5;
  background: #fff;
}

.items-menu {
  color: #fff;
  background: #6d7fcc;
}

li a.dropdown-toggle::after {
  display: inline-flex;
  position: relative;
  /* left: 60%; */
  top: 10%;
}

.sidebar-header > span {
  position: relative;
  float: right;
  margin: 0.5em;
  font-size: 2rem;
  cursor: pointer;
  display: none;
}
.side-menu {
  height: calc(100vh - 130px);
  overflow-y: scroll;
  padding: 10px 0px 10px 30px;
}

.side-menu::-webkit-scrollbar {
  width: 10px;
}

.side-menu::-webkit-scrollbar-thumb {
  background: #5466b3;
  border-radius: 3px;
}

.side-menu::-webkit-scrollbar-thumb:hover {
  background: #3a4c99;
}

.form-body {
  max-width: 700px;
  width: 100%;
  margin: 45px auto 0;
}
.Logout {
  position: absolute;
  right: 0px;
  background: #2196f3;
  padding: 10px 30px;
  color: #fff;
  cursor: pointer;
  font-weight: bold;
  font-size: 13px;
}
.empty-message {
  display: block;
  text-align: center;
  margin: 10px;
  color: #888;
}
.step {
  display: none;
}
.step.active {
  display: block;
}
a.nav-link {
  cursor: pointer;
}
.buttons {
  width: 50%;
  display: flex;
  gap: 11px;
  padding-bottom: 50px;
}
.buttons button {
  flex: 1;
}
.invalid-tooltip {
  position: relative !important;
}
.gegevens .item {
  display: block;
}
.address-details {
  background: #1594d629;
  padding: 10px;
  margin-bottom: 15px;
}
.address-details .item {
  display: block;
}
.upload-div .file-upload-button {
  background: #1594d6;
  padding: 5px 10px;
  color: #fff;
  border-radius: 8px;
  cursor: pointer;
}

.upload-div {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 30px;
}
.upload-div img {
  flex: 1 0 100%;
  margin-bottom: 10px !important;
}
.info {
  background: #f4f4f4;
  padding: 10px;
  margin-top: 10px;
  box-shadow: rgb(99 99 99 / 40%) 0px 2px 8px 0px;
}
.info span {
  display: block;
  font-size: 13px;
}
span.bigger {
  font-weight: bold;
  font-size: 18px;
  color: green;
}
.contact {
  margin-top: 10px;
}
.info.big span {
  font-size: 18px;
  font-weight: bold;
}
.logo img {
  max-width: 100px;
}
.loader {
  min-height: 420px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #f4f4f4;
}
.loader > div {
  width: 30px;
  height: 30px;
}
.pill.archived {
  display: inline-block;
  background: #dd3545;
  padding: 5px 10px;
  font-size: 11px;
  color: #fff;
  margin-top: 20px;
}
.form.details {
  width: 100%;
  max-width: 1080px;
  margin: 40px auto 0 !important;
  padding: 0 40px;
  position: relative;
}
.image-container img {
  width: 100%;
  height: 150px;
  object-fit: cover;
}
.image-col {
  margin-bottom: 20px;
}
.status {
  position: absolute;
  top: -10px;
  font-size: 28px;
  font-weight: bold;
}
.content-tabs {
  margin: 20px 0;
  border: 1px solid #f4f4f4;
  box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
}
.tab-pane.active {
  border-top: 1px solid #f4f4f4;
  padding: 20px;
}
.image-container.legplan {
  text-align: center;
}
.image-container.legplan button {
  margin-top: 10px;
}
.img-container img {
  max-width: 100%;
}
.legplan-container {
  padding: 20px;
}
.img-container {
  max-width: 180px;
  margin-top: 15px;
}
.mr-5 {
  margin-right: 10px;
}
.chart-block {
  background: #f4f4f4;
  padding: 20px 20px;
  height: 100%;
}
.chart-block h3 {
  font-size: 18px;
  font-weight: bold;
}
span.korting {
  text-decoration: line-through;
  color: #6f6f6f;
}
.pricing {
  background: #fff2df;
  padding: 10px 20px;
  font-size: 12px;
}
.pricing .total {
  font-weight: bold;
  border-top: 1px solid;
}
.text-link {
  font-weight: 500;
  text-decoration: underline;
  margin-bottom: 10px;
  cursor: pointer;
}
.positive-text {
  color: green;
  font-weight: bold;
}
.table .signed {
  background: #6ab5e733;
}
.table .cancelled {
  background: #a6000026;
}
.table .backoffice {
  background: #f974232e;
}
#progress-bar-container {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 35px;
  overflow: hidden;
  margin-top: 12px;
}
.progress {
  color: white;
  text-align: center;
  line-height: 75px;
  font-size: 35px;
  font-family: "Segoe UI";
  animation-direction: reverse;
  background: #e5405e;
  background: linear-gradient(to right, #e5405e 0%, #ffdb3a 45%, #3fffa2 100%);
}
.progress-bullet {
  position: absolute;
  background: #03a9f4;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  top: 1px;
}
.offertes thead th {
  font-size: 11px;
  font-weight: bold;
}
.css-yvszuv-Slider {
  margin-left: 10px;
}
span.change {
  margin-left: 10px;
  background: #f97327;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
}
.nav-fill .nav-item a.nav-link {
  font-weight: bold;
}
.nav-link:focus,
.nav-link:hover {
  color: #f97423 !important;
}
.nav-fill .nav-item a.nav-link.active,
.nav-fill .nav-item a.nav-link.active:hover {
  background: #f97423 !important;
  color: #fff !important;
}
.date span {
  font-weight: bold;
  font-size: 11px;
}
.table .action-col {
  padding: 0px !important;
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100px;
  white-space: nowrap;
}
.actions {
  font-size: 12px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 5px 0;
  gap: 4px;
}
.actions .action {
  text-align: center;
  padding: 13px 10px;
  cursor: pointer;
  text-decoration: underline;
  font-weight: bold;
  flex: 1 0 46%;
}
.table-block .actions .action .action-item.view {
  background: #008bff;
  position: relative;
}
.table-block .actions .action .action-item {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0;
}
.table-block .actions .action .action-item svg {
  margin: 0px 0;
  width: 25px;
  height: 25px;
  padding: 5px;
}

.offerte-row {
  border-bottom: 1px solid #d4d4d4;
}
.client-details span.text-link {
  margin-bottom: 20px;
  display: block;
}
span.status-h {
  font-size: 12px;
  background: #f97329;
  color: #fff;
  padding: 5px 10px;
  border-radius: 8px;
  margin-bottom: 10px;
  display: inline-block;
}
.nav-item .dropdown-menu.dropdown-menu-end.show {
  right: 20px;
}
.status-info {
  font-size: 14px;
}
span.edit-inline {
  font-size: 12px;
  color: #f97423;
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
  margin-left: 10px;
}
.loader-block {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  flex-wrap: wrap;
  padding: 50px 0;
}
.loader-block > div {
  margin-right: 10px;
  width: 30px;
  height: 30px;
}
.loader-block svg {
  color: green;
  font-size: 32px;
  margin-right: 10px;
}
.loader-block .confirm-message {
  font-size: 15px;
  margin-top: 10px;
}
.header {
  color: #f97329;
  font-weight: bold;
  margin-bottom: 10px;
}
.clear-button {
  margin-right: 10px;
  position: absolute;
  top: 45px;
  border: 1px solid !important;
  border-left: 0px !important;
  border-top: 0 !important;
  border-radius: 0 !important;
  border-color: #e6e6e6 !important;
  font-size: 10px !important;
  font-weight: bold !important;
}
.sitting-man-image {
  width: 150px;
  position: absolute;
  top: 10px;
  right: 20px;
  z-index: 1;
}
.sitting-man-image img {
  width: 100%;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.agent-row {
  margin-top: 20px !important;
}
span.edit-inline {
  display: none;
}
.conffeti-overlay {
  position: absolute;
  opacity: 0.4;
  top: -2px;
}
.conffeti-overlay.hide {
  display: none;
}
.conffeti-overlay img {
  width: 100%;
}
.left-container .logo {
  max-width: 180px;
  margin-bottom: 20px;
}
.header-bar-client .logo {
  height: 42px;
  margin-bottom: 20px;
}
.information-block {
  border: 1px solid #ededed;
  margin-bottom: 20px;
  position: relative;
}
.block-header {
  background: #424242;
  padding: 10px 15px;
  display: flex;
  align-items: center;
}
.block-header span {
  font-weight: bold;
  color: #fff;
  font-size: 13px;
  background: #f97423;
  padding: 0 10px;
  border-radius: 3px;
}
.block-header span.link {
  cursor: pointer;
}
.block-header span.green {
  background: #4caf50;
}
.block-header h3 {
  margin: 0;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  display: inline-block;
}
.align-right {
  margin-left: auto;
}
.block-main .block-header {
  background: #f97423;
}
.block-header span.add-button svg {
  background: transparent;
}
.block-header span.add-button:hover svg {
  background: #fff;
  color: #f97423;
}
.information-block .block-content {
  padding: 15px;
  font-size: 15px;
}
.information-block .block-content .block-item {
  display: block;
}
.information-block h4 {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 10px;
}
.information-block .block-content ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.information-block .block-content ul li {
  margin-bottom: 10px;
}
.information-block .block-content ul li a {
  color: #424242;
}
.information-block .block-content svg {
  color: #a09f9f;
  margin-right: 6px;
}
.information-block .block-content .block-item {
  display: block;
  position: relative;
  font-size: 13px;
}
.information-block .block-content .block-item span.align-right {
  position: absolute;
  right: 0;
}
.information-block .block-content.personal .block-item span.align-right {
  display: block;
  position: relative;
}
.information-block .block-content .block-item.border-top {
  border-color: #424242 !important;
  font-weight: bold;
}
.information-block .block-content .block-item.border-bottom {
  border-color: #424242 !important;
  font-weight: bold;
}
.information-block .block-content .block-item.positive {
  color: green;
  font-weight: bold;
  font-size: 18px;
}
.information-block .block-content.personal span {
  font-size: 15px;
  font-weight: 500;
}
.block-header svg {
  background: #919191;
  color: #fff;
  width: 25px;
  height: 25px;
  padding: 3px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
}
.sign-banner {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #fff;
  padding: 20px 0;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.sign-banner button {
  font-size: 21px;
  font-weight: 500;
  padding: 5px 50px;
}
.MyComponent {
  border: 1px solid #ededed;
}
.solar-info {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f1f3f5;
  padding: 10px 0;
}
.solar-info div {
  flex: 1;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-wrap: wrap;
  font-weight: 500;
}
.solar-info div span {
  flex: 1 0 100%;
  font-weight: bold;
  font-size: 18px;
}
.form.details.klant {
  max-width: 1120px;
  margin-top: 10px !important;
}
.container.head-container {
  max-width: 1060px !important;
  position: relative;
}
.stats-block {
  color: #424242;
  text-align: center;
  font-weight: bold;
  margin-bottom: 20px;
  padding-bottom: 10px;
  transition: 0.3s;
}
.stats-block span {
  display: block;
  font-size: 24px;
}
.stats-block:hover {
  cursor: pointer;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  background: #f97225;
  color: #fff;
}
.stats-block.active {
  background: #f97225;
  color: #fff;
}
.content-group {
  margin-bottom: 23px;
}
.content-group:last-child {
  margin: 0;
}
.information-block .block-content.personal span.block-item {
  padding: 5px 0;
  border-top: 1px solid #ededed;
}
.information-block
  .block-content.personal
  .content-group
  .block-item.no-border {
  border-top: 0px solid #000 !important;
}
.modal-dialog.modal-preview {
  max-width: 1080px;
}
.modal-preview .modal-header {
  display: flex;
}
.modal-preview .modal-header-buttons {
  /* background: #b0b1b3; */
  margin-bottom: -1px;
  z-index: 9999;
  position: relative;
  display: flex;
  justify-content: center;
  gap: 21px;
  padding: 0 0 15px;
}
.modal-body .MyComponent {
  border: 0px;
}
.modal-preview .modal-header-buttons .btn-alt {
  color: #207cf5;
}

.modal-preview .modal-header-buttons .btn-alt:hover {
  background: #207cf5;
  border-color: #207cf5;
  color: #fff;
}

.modal-preview .modal-header-buttons button {
  border: 2px solid;
  font-weight: bold;
  font-size: 15px;
}
.table-block table.table.table-borderless th,
table.table.table-borderless tr {
  font-size: 11px;
}
.table-block .actions .action {
  text-align: center;
  padding: 5px 0px;
  cursor: pointer;
  text-decoration: underline;
  font-weight: bold;
}
.details-area {
  padding: 20px;
}
.filled-details.row {
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 10px;
}
.edit-button-block {
  text-align: center;
  display: flex;
  gap: 5px;
}
.edit-button-block button {
  font-size: 12px;
  font-weight: bold;
  flex: 1;
}
.block-content.images .form-label {
  min-height: 43px;
  display: flex;
  align-items: center;
}
.btn-primary.disabled {
  margin-right: 10px;
}
.block-content span.file-upload-button {
  font-size: 13px;
}
.stats-block .status-label {
  font-size: 14px;
  min-height: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.alert .alert-content {
  max-width: 510px;
  margin: 0 auto;
}
.inline-form .form-select {
  display: inline-block;
  width: auto;
  margin-left: 20px;
}
.text-bold {
  font-weight: bold !important;
}
.discount-price {
  color: grey;
  text-decoration: line-through;
  font-size: 14px;
}
.status-block {
  margin-bottom: 10px;
}
.status-block .error {
  margin-top: 10px;
  display: inline-block;
}
button.ml-auto {
  display: block;
}
.status-container {
  padding-right: 10px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: right;
}
.status-container .status-text {
  font-weight: bold;
  margin-left: 20px;
}
.mr-3 {
  margin-right: 20px;
}
.table-block .actions .action svg {
  width: 100%;
  color: #fff;
}
.table-block .actions .action.view {
  background: #008bff;
  position: relative;
}
.table-block .actions .action.delete {
  background: #ea4747;
}
table.table.table-borderless thead tr th {
  padding: 10px 10px !important;
}
.table-block table.table.table-borderless th,
table.table.table-borderless tbody tr th {
  padding: 5px !important;
}
.block-content.table-block.offertes {
  padding: 10px 0 !important;
  text-align: center;
}
span.loader-icon {
  display: inline-block;
}

span.loader-icon div {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 6px;
  margin-left: -2px;
}
.status-bar {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-bottom: 20px;
}
.status-bar .status-pill {
  align-self: flex-start;
  margin-right: 5px;
  padding: 4px 5px !important;
  font-size: 12px !important;
  font-weight: bold !important;
  margin-bottom: 5px;
}
.status-bar .status-pill .badge {
  font-size: 12px !important;
}
.status-bar .status-pill:hover,
.status-bar .status-pill.active {
  background-color: #6c757d;
  color: #fff;
  border-color: #6c757d;
}
.status-bar .status-pill span {
  margin-left: 5px;
}
.error-message {
  font-size: 13px;
  margin-bottom: 15px;
}
.calculators {
  margin-bottom: 20px;
}
.calculators .calculator {
  background: #d8d8d8;
  padding: 15px;
  margin: 5px;
}

.calculators .calculator h3 {
  font-size: 16px;
  font-weight: bold;
}

.calculators .calculator input {
  margin-bottom: 5px;
}
.calculators .calculator .calculator-item {
  display: block;
}
button.rekentool {
  position: absolute;
  top: 0;
  right: 0;
}
.user-page .table-header {
  display: flex;
  gap: 15px;
}
.user-page .table-tab {
  cursor: pointer;
}

.user-page .table-tab:hover,
.user-page .table-tab.active {
  color: #f97423;
}
.user-page .card-item {
  display: block;
  margin-bottom: 10px;
}

.user-page .agent-list {
  display: flex;
  flex-wrap: wrap;
}

.user-page .agent-list li {
  flex: 1 0 50%;
  cursor: pointer;
  text-decoration: underline;
  color: #f97423;
  font-weight: bold;
  font-size: 14px;
}
.modal-dialog.modal-preview.log {
  max-width: 600px;
}

.log-item .message {
  font-weight: bold;
}
.log-item .extra {
  font-style: italic;
  color: #606060;
}
.log-item .date {
  font-size: 11px;
  font-weight: bold;
}
.log-item.row {
  border-bottom: 1px solid #dedede;
  padding: 10px 0;
}
.klant .information-block .block-content .block-item span.align-right {
  position: relative;
}
.log-item:last-child {
  border: 0;
}
td span.discount-price {
  display: block;
}
.table-block .actions .action.view-klant {
  background: green;
}
.table-block .actions .action.edit-klant {
  background: rgb(223, 130, 10);
}
.table-block .actions .action.view-invoice {
  background: rgb(149, 0, 255);
}
.table-block .actions .action.view-planning {
  background: green;
}
.block-settings h3 {
  padding: 20px 10px 0;
}
.block-settings .form-group {
  margin-left: 10px;
}
.block-settings.extra-options.offerte-maken {
  /* margin-left: 10px; */
  background: #f4f4f4;
  padding: 10px;
  position: absolute;
  /* top: 0; */
  /* right: -240px; */
  bottom: 0;
  left: 0;
  right: 0;
}

.extra-product-list {
  padding-left: 13px;
}

.extra-product-list span {
  display: flex;
  margin-bottom: 5px;
  border: 1px dotted;
  align-items: center;
  padding: 6px;
}
.option-button.edit {
  margin-left: auto;
  margin-right: 12px;
}
.option-button {
  cursor: pointer;
}
.block-settings code {
  display: block;
  padding: 10px;
  background: #d5d5d5;
  margin: 10px 0px 10px 10px;
}
.input-block-color {
  background: #f4f4f4;
  padding: 15px;
  margin-bottom: 10px;
}
.input-block-color h4 {
  font-size: 18px;
  font-weight: 700;
}
.input-block-color .required {
  background: #ddefff;
  padding: 6px;
}
select#geslacht {
  padding-right: 30px;
  padding-left: 5px;
}
.log-item .delete {
  cursor: pointer;
}
.log-item .delete svg {
  color: #ff4848;
}
label.form-label.small {
  font-size: 9px;
}
label.form-label.smaller {
  font-size: 11px;
}
.extra-products-item {
  background: #f4f4f4;
  padding: 10px;
  font-size: 13px;
  font-weight: bold;
  text-align: center;
}
.extra-products-item .product-image {
  max-width: 80%;
  margin-top: 10px;
}
.extra-products-item.smaller {
  font-size: 11px;
}
.text-left {
  text-align: left !important;
}
.extra-products-item {
  position: relative;
  margin-bottom: 15px;
}
.extra-products-item span.remove-button {
  position: absolute;
  top: -7px;
  right: -4px;
  background: #000;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.extra-products-item .error-message {
  background: #ffe1a7;
  color: #a92626;
  padding: 10px;
  font-size: 10px;
  border: 1px solid #f57b7b;
  margin-top: 10px;
}
.product-row {
  margin-bottom: 20px;
}

.product-row .product-item {
  background: #f4f4f4;
  padding: 20px;
}
.product-row .product-item .product-image {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 10px 0;
}

.product-row .product-item .product-image img {
  max-width: 100%;
  height: 160px;
}
.tooltip-custom {
  position: absolute;
  right: -120px;
  width: 130px;
  font-size: 10px;
  background: #f4f4f4;
  border-radius: 3px;
  border: 1px solid #c8c8c8;
  bottom:0;
}
.btn-primary.disabled {
  margin: 0;
}
/* .action.view-invoice {
  display: none;
} */
span.table-company {
  display: block;
  font-size: 10px;
  font-weight: bold;
}
.filter-bar-container {
  position: relative;
}

.filter-bar-container .filtered-found {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 12px;
  font-weight: 500;
  color: #9e9e9e;
}
.content-group .text-link {
  display: inline;
}
.edit-link {
  display: inline-flex;
  justify-content: center;
  padding: 3px 10px;
  align-items: center;
  color: #666;
}
.edit-link svg {
  margin: 0 !important;
  color: inherit;
}
.owner.block {
  display: inline-flex;
  background: #e3e8ff;
  color: #505050;
  font-weight: 700;
  font-size: 14px;
  padding: 3px 10px;
  align-items: center;
  top: -10px;
  position: relative;
  margin-right: 10px;
}
.owner.block .owner-label {
  font-size: 11px;
  font-weight: 700;
  color: #000;
}

.owner.block .seperator {
  margin: 0 10px;
}

.loader-container {
  position: absolute;
  top: -151px;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  background: #e7e7e7;
  padding-top: 120px;
}
.loading-container {
  position: absolute;
  /* top: -151px; */
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  background: #e7e7e7;
  padding-top: 120px;
  margin-left: -20px;
}
.table-container {
  position: relative;
}
.loader-container .spinner-border {
  color: #373737;
}

.loader-container .spinner-text {
  height: 30px;
  margin: 4px 10px 10px;
}
.alert-danger {
  position: absolute;
  top: -15px;
  left: 0;
  right: 0;
  margin-left: 10px;
  margin-right: auto;
  text-align: center;
  background: red;
  color: #fff;
}
.alert-danger .block-alertspan.text-link {
  font-weight: bold;
}
.error-block {
  position: absolute;
  top: 0;
  background: #fff;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.error-block .block-message {
  border: 1px solid red;
  padding: 30px;
  text-align: center;
  width: 50%;
  font-weight: bold;
}
.error-block .block-message .icon svg {
  color: #d21010;
  font-size: 61px;
  margin-bottom: 12px;
}

table.max-height-table.table.table-hover tbody {
  font-size: 10px;
  font-weight: bold;
}

.filter-bar {
  display: flex;
  margin-bottom: 11px;
  background: #f4f4f4;
  padding: 20px;
}
.filter-bar .filter-item {
  flex: 1;
}
.filter-bar .filter-item.twice {
  flex: 2;
}
.filter-bar .filter-item .mb-3 {
  margin: 0 !important;
}
.search-results {
  background: #fff;
  border: 1px solid #ced4da;
  border-top: 0;
}
.search-results ul {
  margin: 0;
  padding: 5px 10px;
  list-style: none;
}
.filter-bar input {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.search-results ul li:hover {
  background: #f4f4f4;
  cursor: pointer;
}
.client-info {
  background: #eeeeee;
  position: sticky;
  top: 5px;
}

.client-info {
  background: #eeeeee;
  padding: 10px;
}
.info-container {
  background: #fff;
  padding: 10px;
  position: relative;
}
.info-container h3 {
  font-weight: bold;
  font-size: 18px;
}
.info-container .info-item span {
  font-weight: 500;
}
.info-container .info-item span {
  font-weight: 500;
}

.info-container .info-item .text-link {
  margin-left: 10px;
}

.info-container .info-item .text-link.positive {
  color: green;
}
.info-container.personal .download-box {
  position: absolute;
  right: 8px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  z-index: 99;
}

.range-bar ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0 10px 0 0;
}

.range-bar ul li {
  padding: 2px 7px;
  /* border: 1px solid; */
  border-right: 0px;
  cursor: pointer;
}

.range-bar ul li:hover {
  background: #f4f4f4;
}

.range-bar ul li.active {
  background: #414141;
  color: #fff;
  border-color: #414141;
}
.filtered-results {
  position: absolute;
  background: #fff;
  width: 100%;
  height: 180px;
  overflow: hidden;
  overflow-y: scroll;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.filtered-results .search-result {
  text-align: left;
}
.filtered-results .search-header {
  background: #414141;
  width: 100%;
  display: block;
  padding-left: 15px;
  color: #fff;
}

.filtered-results .search-result ul {
  list-style: none;
  padding: 0 15px 0 15px;
}

.filtered-results .search-result ul li {
  border-bottom: 1px solid;
  font-size: 12px;
  cursor: pointer;
}
.filtered-results .search-result ul li:hover {
  background: #e7e7e7;
}
span.filter-input {
  position: relative;
}
span.filter-input span {
  position: absolute;
  top: 6px;
  right: 8px;
  font-size: 15px !important;
  font-weight: normal !important;
  cursor: pointer;
}
.range-bar {
  margin-bottom: 10px;
}
.range-bar ul li.no-link:hover {
  background: transparent;
}
.range-bar ul li.no-link {
  cursor: default;
}
.search-result .pill {
  background: #ff5656;
  padding: 0px 10px;
  color: #fff;
}
.planning-type {
  margin-bottom: 20px;
}
.planning-type .planning-title {
  font-weight: bold;
}
.planning-type .toggler {
  display: inline-block;
  margin-left: 10px;
  font-size: 21px;
  cursor: pointer;
}
.planning-items .planning-item {
  display: flex;
  margin-bottom: 20px;
  background: #f4f4f4;
  flex-wrap: wrap;
}

.planning-items .planning-item .block {
  flex: 1;
  background: #d7d7d7;
  margin: 10px;
  padding: 10px;
}

.planning-items .planning-item .button-block {
  flex: 1 0 100%;
  display: flex;
}
.planning-items .planning-item .button-block > div {
  flex: 1;
  padding: 0 20px 20px;
}
.status-change {
  display: flex;
}
.status-change > span {
  flex: 2;
  justify-content: center;
  text-align: center;
}
.status-bar-new div.active {
  font-weight: bold;
}
.modal-dialog.modal-width-70 {
  max-width: 60%;
}

.information-block .status-button {
  position: absolute;
  left: 0;
  bottom: 0;
}
.information-block tbody tr {
  position: relative;
}
.status-bar-new {
  display: flex;
  padding: 0px 0 10px;
}
.status-bar-new > div {
  padding: 0 10px;
}
tr.gearchiveerd {
  background: #ffa6a6;
}

.all-statusses {
  display: flex;
  gap: 5px;
  margin-bottom: 10px;
  flex-wrap: wrap;
}
.all-statusses .status-per-user {
  background: #f4f4f4;
  text-align: center;
  padding: 10px 10px;
  flex: 1;
  max-width: 100px;
  min-width: 100px;
}

.all-statusses .statusses-user {
  display: block;
}
.all-statusses .status-username {
  font-weight: bold;
  font-size: 10px;
}

.all-statusses .status-companyname {
  color: #999;
  font-size: 10px;
}
.all-statusses span.statusses-user {
  padding: 0 5px;
  font-size: 10px;
  border-radius: 8px;
  margin-bottom: 5px;
  margin-top: 5px;
  font-weight: bold;
}
.all-statusses span.statusses-user.send {
  background: #f3bd56;
}

.all-statusses span.statusses-user.signed {
  background: #6ed15d;
}
.filter-bar-agents button {
  background: transparent;
  margin-right: 5px;
  margin-bottom: 10px;
  border: 1px solid #d5d5d5;
}

.filter-bar-agents button.active {
  background: #f38900;
  border: #f38900;
  color: #fff;
  font-weight: bold;
}

.status-block.cancel {
  background: #ff5e5e;
  color: #fff;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
}
.cancelled-client {
  background: #ff5e5e;
  float: right;
  margin-right: 41px;
  font-size: 21px;
  font-weight: bold;
  padding: 0 20px;
  color: #fff;
}
.personal-message {
  margin-top: 10px;
}

.personal-message .form-label {
  font-size: 18px;
  margin-bottom: 0;
}

.personal-message .personal-message-description {
  font-size: 13px;
}
.range-dropdown button {
  padding: 0 5px 0 5px;
}
.range-dropdown select {
  max-width: 200px;
  width: 100%;
  margin-top: 10px;
  margin-left: 8px;
  padding: 0 0 0 10px;
}
.range-dropdown span {
  margin-left: 11px;
  font-size: 11px;
  font-weight: bold;
}

h1.title-center {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 40px;
  font-size: 30px;
}
ul.proposition-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}

ul.proposition-list .proposition {
  max-width: 33%;
  margin-right: 30px;
  background: #f4f4f4;
  padding: 30px;
  text-align: center;
}
ul.proposition-list .proposition .image {
  width: 100%;
  margin-bottom: 30px;
}
ul.proposition-list .proposition .image img {
  width: 200px;
}
ul.proposition-list .proposition:hover {
  cursor: pointer;
  background: #eaeaea;
}
.ecolex .rekentool.btn.btn-secondary {
  display: none;
}
.ecolex .block-header span {
  background: #6AB5E7;
}
.ecolex .block-main .block-header {
  background: #6AB5E7;
}
.ecolex .block-header span.add-button svg {
  color: #fff;
}
.ecolex .block-header span.add-button:hover svg {
  background: #fff;
  color: #3a6618;
}
/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */
@media only screen and (max-width: 500px) {
  .content.is-open {
    margin-left: 100%;
  }

  .sidebar.is-open {
    min-width: 100%;
    max-width: 100%;
    margin-left: 0;
    transition: all 0.5s, height 0s;
  }

  .sidebar.is-open > .sidebar-header span {
    display: unset;
  }

  li a.dropdown-toggle::after {
    display: inline-block;
    position: relative;
    left: 68%;
  }
}

@media only screen and (max-width: 790px) {
  .info-block {
    text-align: center;
    justify-content: center;
    flex-wrap: wrap;
  }
  .icon {
    flex: 0 0 100%;
  }
  .info-block .data {
    flex-wrap: wrap;
    margin: 0;
  }
  .form.details.klant {
    margin-top: 10px !important;
    width: 100%;
    max-width: 100%;
    padding: 0;
  }
  .solar-info {
    font-size: 10px;
  }

  .solar-info span {
    font-size: 12px !important;
  }
  .loader {
    height: auto;
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #f4f4f4;
  }
  .offerte {
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 1100px) {
  .information-block .block-content .block-item span.align-right {
    position: relative;
  }
}
